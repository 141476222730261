<template>
  <div>
    <b-button
      v-if="finalDocuments && finalDocuments.length > 0"
      type="is-blue"
      expanded
      class="has-text-weight-medium mt-4"
      @click="open"
      >{{ $t("af:applications.modal.final_documents.button.open") }}</b-button
    >

    <b-modal v-model="modalOpen" :can-cancel="false">
      <div class="af-modal-card">
        <p class="is-size-3 has-text-weight-bold">
          {{ $t("af:applications.modal.final_documents.title") }}
        </p>

        <div v-for="(doc, i) in finalDocuments" :key="i">
          <div class="document-tile tile px-5 py-5 mb-4">
            <b-icon
              icon-pack="far"
              icon="vote-yea"
              type="is-blue"
              size="is-medium"
              class="mr-2"
            ></b-icon>

            <span class="has-text-blue document-filename">
              {{ doc.fileName }}
            </span>

            <div class="ml-auto">
              <span class="cursor-pointer" @click="downloadFile(doc)">
                <b-icon
                  icon-pack="fa"
                  icon="download"
                  type="is-blue"
                  class="mr-2"
                ></b-icon>
              </span>
            </div>
          </div>
        </div>

        <div class="close-btn cursor-pointer" @click="modalOpen = false">
          <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileSaver from "file-saver";

export default {
  name: "ApplicationFinalDocuments",
  props: {
    documents: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      modalOpen: false,
      finalDocuments: [],
    };
  },
  async created() {
    if (this.documents) {
      this.finalDocuments = this.documents.filter(
        (doc) =>
          (doc.documentId && doc.type === "SIGNING") ||
          (doc.documentId && doc.type === "FINAL")
      );
    }
  },
  computed: {
    ...mapGetters({
      getCustomerMetaData: "drawer/getCustomerMetaData",
      getFile: "drawer/getFile",
    }),
  },
  methods: {
    ...mapActions({
      fetchMetadata: "drawer/getCustomerMetaData",
      download: "drawer/download",
    }),
    async downloadFile(document) {
      await this.download(document.documentId);
      let blob = this.getFile(document.documentId);
      FileSaver.saveAs(blob, document.fileName);
    },
    open() {
      this.modalOpen = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.document-tile {
  background: $grey-2;
  border-radius: 8px;
  padding: 1rem;
}

.document-filename {
  padding-top: 0.5rem;
}
</style>
